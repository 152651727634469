import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Master_Bedroom_Hallway: IScene = {
	name: 'Upper Master Bedroom Hallway',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 134,
		y: 121,
	},
	images: Renderings.__upper_master_bedroom_hallway__,
	default: {
		latitude: -0.16655861838182684,
		longitude: 0.9833839683022325,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6091843883826491,
				longitude: 4.58604953979077,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.10694093601172305,
					longitude: 5.362897423399293,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5724402510294873,
				longitude: 0.5654778062708778,
			},
			target: {
				name: 'Upper Ensuite',
				view: {
					latitude: -0.13419473366663093,
					longitude: 0.44692022892098765,
				},
			},
		},
	],
};

export default __Upper_Master_Bedroom_Hallway;
