import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Hallway: IScene = {
	name: 'Basement Hallway',
	pan: '180deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 130,
		y: 180,
	},
	images: Renderings.__basement_hallway__,
	default: {
		latitude: -0.10138782030245608,
		longitude: 1.9242803792719718,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.32804490520170515,
				longitude: 4.673400828764682,
			},
			target: {
				name: 'Basement Recreation Room 1',
				view: {
					latitude: -0.06587844561007872,
					longitude: 4.698358162376923,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.22206248076042812,
				longitude: 1.8701056421281168,
			},
			target: {
				name: 'Basement Bathroom',
				view: {
					latitude: -0.08489793281559166,
					longitude: 1.5777751532652116,
				},
			},
		},
	],
};

export default __Basement_Hallway;
