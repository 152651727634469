import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Hallway_1: IScene = {
	name: 'Main Hallway 1',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 182,
		y: 135,
	},
	images: Renderings.__main_hallway_1__,
	default: {
		latitude: -0.13558943381671185,
		longitude: 5.316035173418448,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.14101290865833538,
				longitude: 5.67922885871299,
			},
			target: {
				name: 'Main Living Kitchen',
				view: {
					latitude: -0.05239840117907013,
					longitude: 5.392284367489423,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.498855281182057,
				longitude: 4.88423377287974,
			},
			target: {
				name: 'Main Living Kitchen Nook',
				view: {
					latitude: -0.09501739953770239,
					longitude: 4.740543164064616,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2240805948728033,
				longitude: 4.7619692518511805,
			},
			target: {
				name: 'Main Living Dining',
				view: {
					latitude: -0.022918852887920504,
					longitude: 4.687685272009962,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4866071566741623,
				longitude: 3.172658650671139,
			},
			target: {
				name: 'Main Hallway 2',
				view: {
					latitude: -0.06930156090850925,
					longitude: 3.0544075227280243,
				},
			},
		},
	],
};

export default __Main_Hallway_1;
