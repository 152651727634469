import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Hallway_2: IScene = {
	name: 'Main Hallway 2',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 182,
		y: 202,
	},
	images: Renderings.__main_hallway_2__,
	default: {
		latitude: -0.12896130360699942,
		longitude: 5.2710647872391,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.45625340179878027,
				longitude: 0.021786642979894164,
			},
			target: {
				name: 'Main Hallway 1',
				view: {
					latitude: -0.06234485261915301,
					longitude: 0.012846618494369268,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7418504086614064,
				longitude: 4.603404525239181,
			},
			target: {
				name: 'Main Hallway 3',
				view: {
					latitude: -0.07798377600338835,
					longitude: 4.696893883432545,
				},
			},
		},
	],
};

export default __Main_Hallway_2;
