import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Bedroom_3: IScene = {
	name: 'Upper Bedroom 3',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 170,
		y: 237,
	},
	images: Renderings.__upper_bedroom_3__,
	default: {
		latitude: -0.24218689391985793,
		longitude: 3.989295952071297,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6891096889868553,
				longitude: 5.805730359115899,
			},
			target: {
				name: 'Upper Hallway 3',
				view: {
					latitude: -0.08820395012398174,
					longitude: 5.647321768163107,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.09944062199505233,
				longitude: 5.809769617883067,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.08880603188544,
					longitude: 5.987590583143826,
				},
			},
		},
	],
};

export default __Upper_Bedroom_3;
