import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Ensuite: IScene = {
	name: 'Upper Ensuite',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 158,
		y: 78,
	},
	images: Renderings.__upper_ensuite__,
	default: {
		latitude: -0.15974516896810487,
		longitude: 0.013243505221711301,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.459667522749855,
				longitude: 3.814207126279894,
			},
			target: {
				name: 'Upper Master Bedroom Hallway',
				view: {
					latitude: -0.103317634193405,
					longitude: 3.968626834881033,
				},
			},
		},
	],
};

export default __Upper_Ensuite;
