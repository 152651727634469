import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Hallway_3: IScene = {
	name: 'Upper Hallway 3',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 148,
		y: 214,
	},
	images: Renderings.__upper_hallway_3__,
	default: {
		latitude: -0.1597451689681062,
		longitude: 5.651545892095243,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5157709450432266,
				longitude: 4.608998319285176,
			},
			target: {
				name: 'Upper Bedroom 2',
				view: {
					latitude: -0.08557519431735816,
					longitude: 4.2910821594723645,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7609941661078583,
				longitude: 1.987045777517076,
			},
			target: {
				name: 'Upper Bedroom 3',
				view: {
					latitude: -0.11443471741249311,
					longitude: 2.6037833023307493,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5974496034343444,
				longitude: 0.2403447421435876,
			},
			target: {
				name: 'Upper Laundry',
				view: {
					latitude: -0.0462978223664372,
					longitude: 0.6321881994732543,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2791781781627638,
				longitude: 5.743515136293712,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.3896017473696103,
					longitude: 5.534834318610195,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.18106189119198035,
				longitude: 5.899887019502804,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.07367605044025982,
					longitude: 6.186141794054639,
				},
			},
		},
	],
};

export default __Upper_Hallway_3;
