import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Hallway_3: IScene = {
	name: 'Main Hallway 3',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 149,
		y: 202,
	},
	images: Renderings.__main_hallway_3__,
	default: {
		latitude: -0.3011242443029234,
		longitude: 5.217869168395958,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -1.019491539042484,
				longitude: 6.147816497220385,
			},
			target: {
				name: 'Main Powder',
				view: {
					latitude: -0.2176594889847676,
					longitude: 6.2696760274977725,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7566619694817209,
				longitude: 1.6870433230085167,
			},
			target: {
				name: 'Main Hallway 2',
				view: {
					latitude: -0.23425623820945374,
					longitude: 1.4743581120161702,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7575655409519819,
				longitude: 4.672254912733953,
			},
			target: {
				name: 'Basement Recreation Room 1',
				view: {
					latitude: -0.1869989666230052,
					longitude: 0.617293227517121,
				},
			},
		},
	],
};

export default __Main_Hallway_3;
