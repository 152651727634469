import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Entry_Hallway: IScene = {
	name: 'Main Entry Hallway',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 49,
		y: 202,
	},
	images: Renderings.__main_entry_hallway__,
	default: {
		latitude: -0.04391652893475184,
		longitude: 0.7637295238311719,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5023469821818303,
				longitude: 0.010800393414261138,
			},
			target: {
				name: 'Main Living Dining',
				view: {
					latitude: -0.11375438542544858,
					longitude: 0.2089612474106713,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.20207569553590443,
				longitude: 1.6630675885113064,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.2606336036590666,
					longitude: 0.025415681859088494,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.08535857887875742,
				longitude: 0.5348078890461775,
			},
			target: {
				name: 'Main Living Kitchen',
				view: {
					latitude: -0.0796011439859674,
					longitude: 0.540071142387823,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.13853126275759653,
				longitude: 0.9025672176468849,
			},
			target: {
				name: 'Main Living Kitchen Nook',
				view: {
					latitude: -0.06515923867310747,
					longitude: 0.9266025197626391,
				},
			},
		},
	],
};

export default __Main_Entry_Hallway;
