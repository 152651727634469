import IImage from './IImage';

/** BASEMENT */
import __basement_bathroom__ from './unit-d-basement-bathroom.jpg';
import __basement_bedroom__ from './unit-d-basement-bedroom.jpg';
import __basement_hallway__ from './unit-d-basement-hallway.jpg';
import __basement_recreation_room_1__ from './unit-d-basement-recreation-room-1.jpg';
import __basement_recreation_room_2__ from './unit-d-basement-recreation-room-2.jpg';
/** MAIN */
import __main_entry__ from './unit-d-main-entry.jpg';
import __main_entry_hallway__ from './unit-d-main-entry-hallway.jpg';
import __main_hallway_1__ from './unit-d-main-hallway-1.jpg';
import __main_hallway_2__ from './unit-d-main-hallway-2.jpg';
import __main_hallway_3__ from './unit-d-main-hallway-3.jpg';
import __main_living_dining__ from './unit-d-main-living-dining.jpg';
import __main_living_kitchen_nook__ from './unit-d-main-living-kitchen-nook.jpg';
import __main_living_kitchen__ from './unit-d-main-living-kitchen.jpg';
import __main_powder__ from './unit-d-main-powder.jpg';
/** UPPER */
import __upper_bathroom__ from './unit-d-upper-bathroom.jpg';
import __upper_bedroom_2__ from './unit-d-upper-bedroom-2.jpg';
import __upper_bedroom_3__ from './unit-d-upper-bedroom-3.jpg';
import __upper_ensuite__ from './unit-d-upper-ensuite.jpg';
import __upper_hallway_1__ from './unit-d-upper-hallway-1.jpg';
import __upper_hallway_2__ from './unit-d-upper-hallway-2.jpg';
import __upper_hallway_3__ from './unit-d-upper-hallway-3.jpg';
import __upper_laundry__ from './unit-d-upper-laundry.jpg';
import __upper_master_bedroom_hallway__ from './unit-d-upper-master-bedroom-hallway.jpg';
import __upper_master_bedroom__ from './unit-d-upper-master-bedroom.jpg';

const Renderings: IImage = {
	/** BASEMENT */
	__basement_bathroom__,
	__basement_bedroom__,
	__basement_hallway__,
	__basement_recreation_room_1__,
	__basement_recreation_room_2__,
	/** MAIN */
	__main_entry__,
	__main_entry_hallway__,
	__main_hallway_1__,
	__main_hallway_2__,
	__main_hallway_3__,
	__main_living_dining__,
	__main_living_kitchen_nook__,
	__main_living_kitchen__,
	__main_powder__,
	/** UPPER */
	__upper_bathroom__,
	__upper_bedroom_2__,
	__upper_bedroom_3__,
	__upper_ensuite__,
	__upper_hallway_1__,
	__upper_hallway_2__,
	__upper_hallway_3__,
	__upper_laundry__,
	__upper_master_bedroom_hallway__,
	__upper_master_bedroom__,
};

export default Renderings;
