import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Recreation_Room_2: IScene = {
	name: 'Basement Recreation Room 2',
	pan: '180deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 100,
		y: 115,
	},
	images: Renderings.__basement_recreation_room_2__,
	default: {
		latitude: -0.1376014583734979,
		longitude: 5.862752088702026,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1601222128702966,
				longitude: 3.830840252038641,
			},
			target: {
				name: 'Basement Recreation Room 1',
				view: {
					latitude: -0.08212700301803721,
					longitude: 3.9268801190799936,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6320791058488573,
				longitude: 2.0434021125650608,
			},
			target: {
				name: 'Basement Bedroom',
				view: {
					latitude: -0.11108947965237581,
					longitude: 2.2384982654502754,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.39421301874929626,
				longitude: 2.7325795565252937,
			},
			target: {
				name: 'Basement Hallway',
				view: {
					latitude: -0.10309118265588824,
					longitude: 2.8944208423524955,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.0933387659406344,
				longitude: 3.6652007449930757,
			},
			target: {
				name: 'Main Hallway 3',
				view: {
					latitude: -0.06571353226907073,
					longitude: 1.6977850384570663,
				},
			},
		},
	],
};

export default __Basement_Recreation_Room_2;
