import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Hallway_2: IScene = {
	name: 'Upper Hallway 2',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 81,
		y: 156,
	},
	images: Renderings.__upper_hallway_2__,
	default: {
		latitude: -0.08328548657404866,
		longitude: 2.206898877634905,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6481897075574761,
				longitude: 1.6357337387439288,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.059617682370097125,
					longitude: 1.5929997011981272,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3175163721540286,
				longitude: 1.764712228205626,
			},
			target: {
				name: 'Upper Laundry',
				view: {
					latitude: -0.02090937451563768,
					longitude: 1.7117954103736237,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.05766537942577665,
				longitude: 2.2532675462908704,
			},
			target: {
				name: 'Upper Bedroom 3',
				view: {
					latitude: -0.10251118093846445,
					longitude: 2.385536899170394,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6921254391125231,
				longitude: 0.2816389531934326,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.13760145837348592,
					longitude: 0.5032418813494632,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8026638129163026,
				longitude: 4.579929650180959,
			},
			target: {
				name: 'Upper Bathroom',
				view: {
					latitude: -0.10340742853845564,
					longitude: 4.699578821476619,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6228158926029552,
				longitude: 1.9830267691601864,
			},
			target: {
				name: 'Main Entry Hallway',
				view: {
					latitude: -0.0634558763196762,
					longitude: 0.6984998274273052,
				},
			},
		},
	],
};

export default __Upper_Hallway_2;
