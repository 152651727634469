import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Master_Bedroom: IScene = {
	name: 'Upper Master Bedroom',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 97,
		y: 110,
	},
	images: Renderings.__upper_master_bedroom__,
	default: {
		latitude: -0.20403259015732145,
		longitude: 5.355857216845722,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6486664722408477,
				longitude: 1.9101119517057439,
			},
			target: {
				name: 'Upper Master Bedroom Hallway',
				view: {
					latitude: -0.10183084895142525,
					longitude: 2.0422310339576093,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5068629852990654,
				longitude: 2.719235450548122,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.11071855297304323,
					longitude: 2.625093981949981,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6486664722408477,
				longitude: 1.9101119517057439,
			},
			target: {
				name: 'Upper Hallway 2',
				view: {
					latitude: -0.1144156067435338,
					longitude: 3.599714601499019,
				},
			},
		},
	],
};

export default __Upper_Master_Bedroom;
