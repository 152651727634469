import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Entry: IScene = {
	name: 'Main Entry',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 50,
		y: 234,
	},
	images: Renderings.__main_entry__,
	default: {
		latitude: -0.15974516896810487,
		longitude: 0.013243505221711301,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8611716412248422,
				longitude: 6.054992250209782,
			},
			target: {
				name: 'Main Entry Hallway',
				view: {
					latitude: -0.0762804136499442,
					longitude: 0.049852579300221855,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.34873289597176904,
				longitude: 6.258854727844732,
			},
			target: {
				name: 'Main Living Dining',
				view: {
					latitude: -0.12648028339664075,
					longitude: 0.00011133731418500612,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.06722395005766413,
				longitude: 0.8510402981744157,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.20399151488378253,
					longitude: 0.05695500040267054,
				},
			},
		},
	],
};

export default __Main_Entry;
