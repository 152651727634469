import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Recreation_Room_1: IScene = {
	name: 'Basement Recreation Room 1',
	pan: '180deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 35,
		y: 180,
	},
	images: Renderings.__basement_recreation_room_1__,
	default: {
		latitude: -0.13078800895976306,
		longitude: 0.7412008628597819,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.21521362011157685,
				longitude: 0.8172270313484563,
			},
			target: {
				name: 'Basement Recreation Room 2',
				view: {
					latitude: -0.06776360188280117,
					longitude: 0.701775706159852,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1944798417085929,
				longitude: 1.0508555409230793,
			},
			target: {
				name: 'Basement Bedroom',
				view: {
					latitude: -0.17677879250242023,
					longitude: 0.8566602503381606,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.32166407815283726,
				longitude: 1.5925158292872503,
			},
			target: {
				name: 'Basement Hallway',
				view: {
					latitude: -0.1252348932504992,
					longitude: 1.6595686128581244,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.23996435659220094,
				longitude: 1.5922058911843078,
			},
			target: {
				name: 'Basement Bathroom',
				view: {
					latitude: -0.06606023952936768,
					longitude: 1.5550487404512836,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5885565390622309,
				longitude: 2.630379822813865,
			},
			target: {
				name: 'Main Hallway 3',
				view: {
					latitude: -0.15669024049404623,
					longitude: 1.6799516305889413,
				},
			},
		},
	],
};

export default __Basement_Recreation_Room_1;
