import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Living_Kitchen: IScene = {
	name: 'Main Living Kitchen',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 128,
		y: 71,
	},
	images: Renderings.__main_living_kitchen__,
	default: {
		latitude: -0.05359232122545299,
		longitude: 3.94489349143134,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.261931209574197,
				longitude: 4.026334680848372,
			},
			target: {
				name: 'Main Living Dining',
				view: {
					latitude: -0.057539009822320075,
					longitude: 3.8636273506442524,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4310900181199946,
				longitude: 3.215591702720749,
			},
			target: {
				name: 'Main Living Kitchen Nook',
				view: {
					latitude: -0.0658384034193853,
					longitude: 3.014817458795365,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.10581830886806332,
				longitude: 3.7213522979904714,
			},
			target: {
				name: 'Main Entry Hallway',
				view: {
					latitude: -0.06356047123390995,
					longitude: 3.701692617355646,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.08539838759433671,
				longitude: 2.6456049145613476,
			},
			target: {
				name: 'Main Hallway 1',
				view: {
					latitude: -0.03229124785029347,
					longitude: 4.013240223975895,
				},
			},
		},
	],
};

export default __Main_Living_Kitchen;
