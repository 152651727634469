import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Powder: IScene = {
	name: 'Main Powder',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 145,
		y: 180,
	},
	images: Renderings.__main_powder__,
	default: {
		latitude: -0.22447293839849447,
		longitude: 6.2696760274977725,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: 0.06343155905563402,
				longitude: 6.275514202407177,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.11285338456919236,
					longitude: 0.0043354612463208176,
				},
			},
		},
	],
};

export default __Main_Powder;
