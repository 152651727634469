import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Bathroom: IScene = {
	name: 'Basement Bathroom',
	pan: '180deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 151,
		y: 182,
	},
	images: Renderings.__basement_bathroom__,
	default: {
		latitude: -0.15974516896810487,
		longitude: 0.013243505221711301,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.11589178740284778,
				longitude: 4.474202036990738,
			},
			target: {
				name: 'Basement Hallway',
				view: {
					latitude: 0.026453864547030026,
					longitude: 4.726094232889353,
				},
			},
		},
	],
};

export default __Basement_Bathroom;
