import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Living_Dining: IScene = {
	name: 'Main Living Dining',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 50,
		y: 140,
	},
	images: Renderings.__main_living_dining__,
	default: {
		latitude: -0.12056783483917455,
		longitude: 0.6581264255277788,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.12735887965679105,
				longitude: 0.672825748476722,
			},
			target: {
				name: 'Main Living Kitchen',
				view: {
					latitude: -0.12379587415691962,
					longitude: 0.6668272698946139,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4059712143098597,
				longitude: 1.4216769828481681,
			},
			target: {
				name: 'Main Living Kitchen Nook',
				view: {
					latitude: -0.11115527339325304,
					longitude: 1.0865233785222614,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.24723009835451593,
				longitude: 1.4967666539487743,
			},
			target: {
				name: 'Main Hallway 1',
				view: {
					latitude: -0.0887940884469769,
					longitude: 1.440698857229343,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.46424227034270604,
				longitude: 3.3605689443361633,
			},
			target: {
				name: 'Main Entry Hallway',
				view: {
					latitude: -0.07049228356739556,
					longitude: 3.1271026295478324,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.28863560788337495,
				longitude: 3.260251688320884,
			},
			target: {
				name: 'Main Entry',
				view: {
					latitude: -0.13030458207207563,
					longitude: 3.1552782701297555,
				},
			},
		},
	],
};

export default __Main_Living_Dining;
