import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Bedroom_2: IScene = {
	name: 'Upper Bedroom 2',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 97,
		y: 217,
	},
	images: Renderings.__upper_bedroom_2__,
	default: {
		latitude: -0.21503073317816002,
		longitude: 4.344587729279414,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.45961738296880617,
				longitude: 1.2079902569318375,
			},
			target: {
				name: 'Upper Hallway 3',
				view: {
					latitude: -0.14695750680024533,
					longitude: 1.5501126910045633,
				},
			},
		},
	],
};

export default __Upper_Bedroom_2;
