import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Living_Kitchen_Nook: IScene = {
	name: 'Main Living Kitchen Nook',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 121,
		y: 134,
	},
	images: Renderings.__main_living_kitchen_nook__,
	default: {
		latitude: -0.14782163249408287,
		longitude: 5.433299488934885,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4917387763256542,
				longitude: 6.232137233120415,
			},
			target: {
				name: 'Main Living Kitchen',
				view: {
					latitude: -0.09354973371901609,
					longitude: 5.9587787009308935,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5096428381587597,
				longitude: 1.798132377824657,
			},
			target: {
				name: 'Main Hallway 1',
				view: {
					latitude: 0.09774727533484207,
					longitude: 1.4619143535834844,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.34741152994527846,
				longitude: 4.502174803805981,
			},
			target: {
				name: 'Main Living Dining',
				view: {
					latitude: -0.08990731247740458,
					longitude: 4.708158213918238,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.16464947534009822,
				longitude: 3.9903723142165366,
			},
			target: {
				name: 'Main Entry Hallway',
				view: {
					latitude: -0.09122857472158419,
					longitude: 2.5171868685538312,
				},
			},
		},
	],
};

export default __Main_Living_Kitchen_Nook;
