import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Bathroom: IScene = {
	name: 'Upper Bathroom',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 61,
		y: 158,
	},
	images: Renderings.__upper_bathroom__,
	default: {
		latitude: -0.15974516896810487,
		longitude: 0.013243505221711301,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.38224208847665264,
				longitude: 1.6125992651348844,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.048947707647693406,
					longitude: 1.5808576835985122,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8020553784522684,
				longitude: 1.7641123764860633,
			},
			target: {
				name: 'Upper Hallway 2',
				view: {
					latitude: -0.035591340677962435,
					longitude: 1.5169586353860522,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.22485189782026183,
				longitude: 1.7031341901430317,
			},
			target: {
				name: 'Upper Laundry',
				view: {
					latitude: -0.05410495182079922,
					longitude: 1.5050195414560184,
				},
			},
		},
	],
};

export default __Upper_Bathroom;
