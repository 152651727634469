import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Hallway_1: IScene = {
	name: 'Upper Hallway 1',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 128,
		y: 156,
	},
	images: Renderings.__upper_hallway_1__,
	default: {
		latitude: -0.1669295106362907,
		longitude: 4.4147144878648525,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4155968682979756,
				longitude: 4.638462786154731,
			},
			target: {
				name: 'Upper Bathroom',
				view: {
					latitude: -0.20483098505592778,
					longitude: 4.7173240307826445,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5891200183462297,
				longitude: 4.602280926090732,
			},
			target: {
				name: 'Upper Hallway 2',
				view: {
					latitude: -0.1043046628372637,
					longitude: 4.69809546708212,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5388077160800746,
				longitude: 5.29940952947555,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.21936285133820732,
					longitude: 5.39528237354565,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5348162257548377,
				longitude: 1.9226686720051773,
			},
			target: {
				name: 'Upper Laundry',
				view: {
					latitude: -0.03096756118555044,
					longitude: 2.333102102813279,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1544585318192162,
				longitude: 2.780296150165723,
			},
			target: {
				name: 'Upper Hallway 3',
				view: {
					latitude: -0.10735408458565887,
					longitude: 2.6777356087348343,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.145035100944924,
				longitude: 2.647991933474661,
			},
			target: {
				name: 'Upper Bedroom 3',
				view: {
					latitude: -0.2051634478702904,
					longitude: 2.8418963110555993,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8318722563520682,
				longitude: 3.1158623027234102,
			},
			target: {
				name: 'Main Entry Hallway',
				view: {
					latitude: -0.0682630804865294,
					longitude: 0.6359958017692592,
				},
			},
		},
	],
};

export default __Upper_Hallway_1;
