import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Bedroom: IScene = {
	name: 'Basement Bedroom',
	pan: '180deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 130,
		y: 115,
	},
	images: Renderings.__basement_bedroom__,
	default: {
		latitude: -0.14611827014065026,
		longitude: 0.7299365323740872,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5745696706151069,
				longitude: 3.8290267900004795,
			},
			target: {
				name: 'Basement Recreation Room 2',
				view: {
					latitude: -0.06776360188280162,
					longitude: 3.997097352008721,
				},
			},
		},
	],
};

export default __Basement_Bedroom;
