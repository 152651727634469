import IScene from './IScene';
/** BASEMENT */
import __Basement_Bathroom from './__Basement_Bathroom';
import __Basement_Bedroom from './__Basement_Bedroom';
import __Basement_Hallway from './__Basement_Hallway';
import __Basement_Recreation_Room_1 from './__Basement_Recreation_Room_1';
import __Basement_Recreation_Room_2 from './__Basement_Recreation_Room_2';
/** MAIN */
import __Main_Entry from './__Main_Entry';
import __Main_Entry_Hallway from './__Main_Entry_Hallway';
import __Main_Hallway_1 from './__Main_Hallway_1';
import __Main_Hallway_2 from './__Main_Hallway_2';
import __Main_Hallway_3 from './__Main_Hallway_3';
import __Main_Living_Dining from './__Main_Living_Dining';
import __Main_Living_Kitchen_Nook from './__Main_Living_Kitchen_Nook';
import __Main_Living_Kitchen from './__Main_Living_Kitchen';
import __Main_Powder from './__Main_Powder';
/** UPPER */
import __Upper_Bathroom from './__Upper_Bathroom';
import __Upper_Bedroom_2 from './__Upper_Bedroom_2';
import __Upper_Bedroom_3 from './__Upper_Bedroom_3';
import __Upper_Ensuite from './__Upper_Ensuite';
import __Upper_Hallway_1 from './__Upper_Hallway_1';
import __Upper_Hallway_2 from './__Upper_Hallway_2';
import __Upper_Hallway_3 from './__Upper_Hallway_3';
import __Upper_Laundry from './__Upper_Laundry';
import __Upper_Master_Bedroom_Hallway from './__Upper_Master_Bedroom_Hallway';
import __Upper_Master_Bedroom from './__Upper_Master_Bedroom';

const Scenes: Array<IScene> = [
	/** BASEMENT */
	__Basement_Bathroom,
	__Basement_Bedroom,
	__Basement_Hallway,
	__Basement_Recreation_Room_1,
	__Basement_Recreation_Room_2,
	/** MAIN */
	__Main_Entry,
	__Main_Entry_Hallway,
	__Main_Living_Dining,
	__Main_Living_Kitchen,
	__Main_Living_Kitchen_Nook,
	__Main_Hallway_1,
	__Main_Hallway_2,
	__Main_Hallway_3,
	__Main_Powder,
	/** UPPER */
	__Upper_Bathroom,
	__Upper_Bedroom_2,
	__Upper_Bedroom_3,
	__Upper_Ensuite,
	__Upper_Hallway_1,
	__Upper_Hallway_2,
	__Upper_Hallway_3,
	__Upper_Laundry,
	__Upper_Master_Bedroom_Hallway,
	__Upper_Master_Bedroom,
];

export default Scenes;
