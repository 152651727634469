import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Laundry: IScene = {
	name: 'Upper Laundry',
	pan: '180deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 179,
		y: 172,
	},
	images: Renderings.__upper_laundry__,
	default: {
		latitude: 0.003099685883078518,
		longitude: 6.2578182145503645,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5186674503307307,
				longitude: 4.6497535226605144,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.12604881415394198,
					longitude: 4.721667493600029,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6467183196148807,
				longitude: 4.18243649676524,
			},
			target: {
				name: 'Upper Hallway 3',
				view: {
					latitude: -0.06749785228152838,
					longitude: 4.09885551897851,
				},
			},
		},
	],
};

export default __Upper_Laundry;
